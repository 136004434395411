import { Company } from '@hum/types';
import {
  companyRequestStateChanged,
  companySettingsRequestStateChanged,
  apiUserReviewSessionRequestStateChanged,
  companyFilesRequestStateChanged,
  apiConnectorDisconnected,
  apiFailedSentDealToArchive,
  apiSuccessfullySentDealsToArchive,
} from '../../actions';
import {
  apiSuccessfullyWhenUserAcceptMNDA,
  apiFailedWhenUserAcceptMNDA,
  apiSuccessfullyWhenUserSentFundraisingForm,
  apiFailedWhenUserSentFundraisingForm,
  apiSuccessfullyWhenUserSentUserInfoForm,
  apiFailedWhenUserSentUserInfoForm,
} from '@hum/icm-app/src/pages/onboarding-v4/ducks/actions';
import { select, call, put } from 'redux-saga/effects';
import { request } from '@hum/common/src/ducks/sagas/util';
import {
  AppState,
  Connector,
  getUserCompanyId,
  routeMatches,
  MatchOutReasonsPayloadType,
  MNDAPayloadType,
  FinancialDetailsPayload,
  UserInfoPayload,
} from '../../state';
import { API } from '../../backend/api';
import { getAPI } from '../utils';
import {
  apiConnectorDeletedFailed,
  apiRequestFailed,
} from '@hum/icm-app/src/actions/api';
import { getHistory } from './api';
import { History } from 'history';
import { toast } from '@hum/common/src/modules/toast';

export function* loadCurrentCompany(companyId?: number) {
  const api: API = yield getAPI();
  if (!companyId) {
    const state: AppState = yield select();
    companyId = getUserCompanyId(state);
  }

  return yield request(companyRequestStateChanged, () => {
    return api.legacy.getCompany(companyId!);
  });
}

export function* loadCompanySettings(company: Company) {
  const api: API = yield getAPI();
  yield request(companySettingsRequestStateChanged, () => {
    return api.legacy.getCompanySettings(company.id);
  });
}

export function* loadCompanyFiles(companyId: number) {
  const api: API = yield getAPI();
  yield request(companyFilesRequestStateChanged, () =>
    api.legacy.getCompanyFiles(companyId)
  );
}

export function* loadSession() {
  const api: API = yield getAPI();
  return yield request(apiUserReviewSessionRequestStateChanged, () =>
    api.auth.userReviewSession()
  );
}

export function* deleteConnector(
  connector: Connector,
  airbyteEnabled: boolean
) {
  const api: API = yield getAPI();
  try {
    yield call(
      api.legacy.deleteConnector,
      connector.companyId,
      connector.id,
      connector.connectorType,
      airbyteEnabled
    );
  } catch (e: any) {
    yield call(() => toast.error('Failed to delete connector'));
    yield put(apiConnectorDeletedFailed(e));
    throw e;
  }
  yield put(
    apiConnectorDisconnected({
      companyId: connector.companyId,
      connectorId: connector.id,
    })
  );
}

export function* requestForConnectors(
  companyId: number,
  connectorTypes: string[]
) {
  const api: API = yield getAPI();
  try {
    yield call(api.legacy.requestForConnectors, companyId, connectorTypes);
  } catch (error: any) {
    yield put(apiRequestFailed({ error }));
    throw error;
  }
}

export function* acceptFeeTos(companyId: number) {
  const api: API = yield getAPI();
  try {
    yield call(api.legacy.acceptFeeTos, companyId);
  } catch (error: any) {
    yield put(apiRequestFailed({ error }));
    throw error;
  }
}

export function* acceptMNDA(companyId: number, payload: MNDAPayloadType) {
  const api: API = yield getAPI();
  try {
    yield call(api.legacy.acceptMNDA, companyId, payload);
    yield call(loadCurrentCompany, companyId);
    yield put(apiSuccessfullyWhenUserAcceptMNDA());
    yield call(loadSession);
  } catch (error) {
    yield call(() => toast.error('Failed to accept MNDA'));
    yield put(apiFailedWhenUserAcceptMNDA());
    throw error;
  }
}

export function* sendFundraisingForm(
  companyId: number,
  payload: FinancialDetailsPayload
) {
  const api: API = yield getAPI();
  try {
    yield call(api.capitalApplication.saveFinancialDetails, companyId, payload);
    yield call(loadCurrentCompany, companyId);
    yield put(apiSuccessfullyWhenUserSentFundraisingForm());
    yield call(() => toast.success('Saved your information'));
  } catch (e) {
    yield call(() => toast.error('Failed to save'));
    yield put(apiFailedWhenUserSentFundraisingForm());
    throw e;
  }
}

export function* sendUserInfoForm(companyId: number, payload: UserInfoPayload) {
  const api: API = yield getAPI();
  try {
    yield call(api.capitalApplication.saveUserInfo, companyId, payload);
    yield call(() => toast.success('Saved your information'));
    yield put(apiSuccessfullyWhenUserSentUserInfoForm());
    // session data isn't immediately available to the user until after they submit the form. After the form is submitted
    // session data is returned from the server, and it will be available when the user refreshes or returns to the user info form
    yield call(loadSession);
  } catch (e) {
    yield call(() => toast.error('Failed to save'));
    yield put(apiFailedWhenUserSentUserInfoForm());
    throw e;
  }
}

export function withSession(callback: (...args: any[]) => any) {
  return function* (...args: any[]) {
    const history: History = yield getHistory();
    while (1) {
      const state: AppState = yield select();
      if (state.session.loaded) {
        yield call(callback, ...args);
        break;
      } else {
        const result = yield call(loadSession);
        if (result.loaded && !result.data) {
          history?.push('/login');
          break;
        }
      }
    }
  };
}

export function* locationMatches(route: string) {
  const state: AppState = yield select();
  return routeMatches(route)(state.history?.pathname || '');
}

// SYNDICATION

export function* apiSendDealToArchive(
  companyId: number,
  matchUuid: string,
  userType: string,
  payload: MatchOutReasonsPayloadType
) {
  const api: API = yield getAPI();
  try {
    yield call(
      api.syndication.sendDealToArchive,
      companyId,
      matchUuid,
      userType,
      payload
    );
    yield call(() => toast.success('Successfully archived.'));
    yield put(apiSuccessfullySentDealsToArchive());
  } catch (e) {
    yield call(() => toast.error('Failed to archive.'));
    yield put(apiFailedSentDealToArchive());
    throw e;
  }
}

export function* apiCompanyIsFundraising(
  companyId: number,
  matchUuid: string,
  isFundraising: boolean
) {
  const api: API = yield getAPI();
  try {
    yield call(
      api.syndication.companyIsFundraising,
      companyId,
      matchUuid,
      isFundraising
    );
  } catch (e) {
    throw e;
  }
}
