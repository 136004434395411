import { call, put, fork, takeEvery } from 'redux-saga/effects';
import { catchError } from '@hum/icm-app/src/modules/sagas';
import { noop } from 'lodash';
import { loadCurrentCompany } from '@hum/icm-app/src/sagas/utils';
import {
  AboutYouFormSubmitted,
  apiFailedWhenOnboardingV5SaveRequestSent,
  apiSucceededWhenOnboardingV5SaveRequestSent,
  BasicBusinessFormSubmitted,
  FundraisingGoalsFormSubmitted,
  OnboardingActionType,
  ReviewAndConfirmFormSubmitted,
} from '@hum/icm-app/src/actions/onboarding';
import { Routes } from '@hum/icm-app/src/state';
import { API } from '@hum/icm-app/src/backend';
import { getAPI, getHistory } from './utils';
import { History } from 'history';
import { SENSITIVE_COUNTRIES } from '../components/constants';
import { toast } from '@hum/common/src/modules/toast';

export function* handleV5SignupSaga() {
  yield fork(handleSignupV5SaveButtonClicked);
}

function* handleSignupV5SaveButtonClicked() {
  const history: History = yield getHistory();
  yield takeEvery(
    OnboardingActionType.BASIC_BUSINESS_FORM_SUBMITTED,
    catchError(function* ({
      payload: { companyId, payload },
    }: BasicBusinessFormSubmitted) {
      if (SENSITIVE_COUNTRIES.includes(payload.country!)) {
        yield call(saveSignUpData, companyId, payload);
        history.push(Routes.UNSUPPORTED_COUNTRY);
      } else {
        yield call(saveSignUpData, companyId, payload);
        history.push(Routes.SHARE_YOUR_GOALS);
      }
    },
    noop)
  );
  yield takeEvery(
    OnboardingActionType.ABOUT_YOU_FORM_SUBMITTED,
    catchError(function* ({
      payload: { companyId, payload },
    }: AboutYouFormSubmitted) {
      yield call(saveSignUpData, companyId, payload);
      history.push(Routes.FUNDRAISING_HISTORY);
    },
    noop)
  );
  yield takeEvery(
    OnboardingActionType.FUNDRAISING_GOALS_FORM_SUBMITTED,
    catchError(function* ({
      payload: { companyId, payload },
    }: FundraisingGoalsFormSubmitted) {
      yield call(saveSignUpData, companyId, payload);
      history.push(Routes.COMPLETE_SIGNUP);
    },
    noop)
  );
  yield takeEvery(
    OnboardingActionType.REVIEW_AND_CONFIRM_FORM_SUBMITTED,
    catchError(function* ({
      payload: { companyId, payload },
    }: ReviewAndConfirmFormSubmitted) {
      yield call(saveSignUpData, companyId, payload);
      history.push(Routes.ONBOARDING_V5_OVERVIEW);
    },
    noop)
  );
}

function* saveSignUpData(companyId: number, payload: any) {
  const api: API = yield getAPI();
  try {
    yield call(api.signupv5.putSignUpData, companyId, payload);
    yield call(loadCurrentCompany, companyId);
    if (!SENSITIVE_COUNTRIES.includes(payload.country!)) {
      yield call(() => toast.success('Company info saved!'));
      yield put(apiSucceededWhenOnboardingV5SaveRequestSent());
    }
  } catch (error) {
    yield call(() => toast.error('Failed to save company data'));
    yield put(apiFailedWhenOnboardingV5SaveRequestSent());
    throw error;
  }
}
